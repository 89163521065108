import { Container } from '@/components/ui/flexbox';

import Info from './Info';
import Wrapper from './Wrapper';
import { SectionHeader } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

const CustomerValue = ({ content }) => {
  if (!content) return null;

  const {
    upperLabel = 'Testimonials',
    title,
    subtitle,
    description: quote,
    logo,
    image,
    companyName = 'Sword member',
    customerName = 'Marcelo',
    id,
  } = content;

  return (
    <Wrapper>
      <Container>
        <SectionHeader
          label={upperLabel}
          title={<MarkdownRichText elements={['strong', 'b', 'i', 'u']}>{title}</MarkdownRichText>}
          description={subtitle !== ' ' ? subtitle : null}
          titleSize="2xl"
          xAlign="center"
        />
        <Info
          id={id}
          quote={quote}
          companyName={companyName}
          customerName={customerName}
          logo={logo}
          image={image}
        />
      </Container>
    </Wrapper>
  );
};

CustomerValue.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default CustomerValue;
