import { Container, SectionHeader } from '@swordhealth/ui-corporate';
import Testimonial from './Testimonial';

import { GridContent, TestimonialColumn, TabletGrid } from './styles';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import TestimonialsSlider from './TestimonialSlider';

function mergeQuotes(quotes, quotesImage) {
  let result = [];
  if (quotesImage.length == 1) {
    result = [...quotes];
    result.splice(3, 0, quotesImage[0]);
  } else if (quotesImage.length == 2) {
    result = [...quotes];
    result.splice(1, 0, quotesImage[0]);
    result.splice(5, 0, quotesImage[1]);
  } else if (quotesImage.length > 2) {
    for (let i = 0; i < 6; i++) {
      if (i < quotes.length) {
        result.push(quotes[i]);
      }
      if (i < quotesImage.length) {
        result.push(quotesImage[i]);
      }
    }
  } else result = quotes;

  return result;
}
function mergeTabletQuotes(quotes, quotesImage) {
  let result = [];
  if (quotesImage.length == 1) {
    result = [...quotes];
    result.splice(4, 0, quotesImage[0]);
  } else if (quotesImage.length == 2) {
    result = [...quotes];
    result.splice(2, 0, quotesImage[0]);
    result.splice(4, 0, quotesImage[1]);
  } else if (quotesImage.length > 2) {
    for (let i = 0; i < 6; i++) {
      if (i < quotesImage.length) {
        result.push(quotesImage[i]);
      }
      if (i < quotes.length) {
        result.push(quotes[i]);
      }
    }
  } else result = quotes;

  return result;
}
const TestimonialGrid = ({ content }) => {
  const { hero, upperlabel, quotes, quotesImage, id, config } = content;

  const allQuotes = mergeQuotes(quotes, quotesImage);
  const tabletQuotes = mergeTabletQuotes(quotes, quotesImage);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );
  const colored = (config !== null || undefined) && config?.colored === false;

  return (
    <Container xAlign={'center'} id={`testimonials-grid-section-${id}`}>
      <SectionHeader {...hero} label={upperlabel} />
      {!isMobile && !isTablet && (
        <GridContent
          {...allQuotes.map((quote, index) => (
            <Testimonial
              key={`quote_${index}`}
              {...quote}
              index={index}
              sectionId={id}
              colored={colored}
            />
          ))}
        >
          <TestimonialColumn>
            {allQuotes.slice(0, 2).map((quote, index) => (
              <Testimonial key={`quote_${index}`} {...quote} index={index} colored={colored} />
            ))}
          </TestimonialColumn>
          <TestimonialColumn>
            {allQuotes.slice(2, 4).map((quote, index) => (
              <Testimonial key={`quote_${index}`} {...quote} index={index} colored={colored} />
            ))}
          </TestimonialColumn>
          <TestimonialColumn>
            {allQuotes.slice(4, 6).map((quote, index) => (
              <Testimonial key={`quote_${index}`} {...quote} index={index} colored={colored} />
            ))}
          </TestimonialColumn>
        </GridContent>
      )}
      {isTablet && (
        <TabletGrid>
          <TestimonialColumn>
            {tabletQuotes.slice(3, 6).map((quote, index) => (
              <Testimonial
                key={`quote_tablet_${index}`}
                {...quote}
                index={index}
                colored={colored}
              />
            ))}
          </TestimonialColumn>
          <TestimonialColumn>
            {tabletQuotes.slice(0, 3).map((quote, index) => (
              <Testimonial
                key={`quote_tablet_${index}`}
                {...quote}
                index={index}
                colored={colored}
              />
            ))}
          </TestimonialColumn>
        </TabletGrid>
      )}
      {isMobile && <TestimonialsSlider quotes={allQuotes.slice(0, 6)} />}
    </Container>
  );
};

TestimonialGrid.defaultConfig = {
  rounded: false,
  colored: true,
  spaceBottom: true,
  spaceTop: true,
  marginBottom: false,
  withGradient: false,
};

export default TestimonialGrid;
