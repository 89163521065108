import { useMotionValue, useSpring } from 'framer-motion';
import { useEffect } from 'react';

export function useCustomCursor(containerRef) {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const scaleValue = useMotionValue(1);

  const springX = useSpring(mouseX, { stiffness: 500, damping: 30 });
  const springY = useSpring(mouseY, { stiffness: 500, damping: 30 });
  const springScale = useSpring(scaleValue, { stiffness: 500, damping: 30 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      mouseX.set(clientX);
      mouseY.set(clientY);

      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const dx = Math.max(rect.left - clientX, 0, clientX - rect.right);
        const dy = Math.max(rect.top - clientY, 0, clientY - rect.bottom);
        const distance = Math.sqrt(dx * dx + dy * dy);
        const threshold = 200;
        const maxScale = 1;
        const minScale = 0;
        const newScale =
          distance < threshold
            ? maxScale - (distance / threshold) * (maxScale - minScale)
            : minScale;
        scaleValue.set(newScale);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseX, mouseY, scaleValue, containerRef]);

  return { springX, springY, springScale };
}
