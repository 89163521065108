import React, { useCallback, useEffect } from 'react';
import { Heading } from '@swordhealth/ui-corporate';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import useAudioTracking from '@/utils/useAudioTracking';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import Image from 'next/image';
import SoundOn from '@/public/assets/icons/volume_up.svg';
import Play from '@/public/assets/icons/circle_play.svg';
import Pause from '@/public/assets/icons/circle_pause.svg';
import {
  Card,
  GroupContainer,
  TranscriptionContainer,
  ScrollableContent,
  DefaultContainer,
  TranscriptContainer,
  Row,
  TranscriptionText,
} from './styles';
import { useAudioPlayback } from '@/components/core/Dynamic/AudioList/AudioRow/hook/useAudioPlayback';
import useReadingTime from '@/utils/useReadingTime';

const AudioRow = ({ audio, index, currentAudio, onPlayAudio }) => {
  const { audioRef, isPlaying, playAudio, stopAudio } = useAudioPlayback(onPlayAudio);

  const transcriptionReadingTime = useReadingTime(audio?.transcription, 8);
  const nameReadingTime = useReadingTime(audio?.name, 4);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  const iconData = getImgData(audio.icon);
  const imageSrc = getImgSrcWorkaround(iconData?.url);
  const audioData = getImgData(audio.audio);
  const audioSrc = audioData?.url;
  const { onAudioExit, ...trackAudio } = useAudioTracking({ src: audioSrc });

  const handleMouseEnter = useCallback(() => {
    if (!isPlaying) playAudio();
  }, [isPlaying, playAudio]);

  const handleMouseLeave = useCallback(() => {
    if (isPlaying) stopAudio();
  }, [isPlaying, stopAudio]);

  const handleClick = useCallback(() => {
    if (isDesktop) !isPlaying && playAudio();
    else isPlaying ? stopAudio() && onAudioExit() : playAudio();
  }, [isDesktop, isPlaying, playAudio, stopAudio, onAudioExit]);

  useEffect(() => {
    if (currentAudio !== audioRef.current) {
      stopAudio();
    }
  }, [audioRef, currentAudio, stopAudio]);

  const renderGroupCards = useCallback(
    () => (
      <GroupContainer $left={index % 2 === 0} $duration={nameReadingTime * 6 * 2}>
        {[...Array(6)].map((_, i) => (
          <Card key={`${audio.id || audio.name}-${i}`}>
            <Image src={imageSrc} alt={iconData?.alternativeText || ''} width={57} height={76} />
            {!isDesktop && <Play />}
            <Heading as="p">{audio.name}</Heading>
          </Card>
        ))}
      </GroupContainer>
    ),
    [audio, imageSrc, iconData, isDesktop, index, nameReadingTime],
  );

  const renderTranscriptionContent = useCallback(
    () => (
      <TranscriptionContainer $duration={transcriptionReadingTime * 2}>
        {[...Array(2)].map((_, i) => (
          <TranscriptionText key={`${audio.id || audio.name}-${i}`}>
            {isDesktop ? <SoundOn /> : <Pause />}
            <Heading as="p" size="5xl">
              {audio?.transcription}
            </Heading>
          </TranscriptionText>
        ))}
      </TranscriptionContainer>
    ),
    [audio, isDesktop, transcriptionReadingTime],
  );

  return (
    <Row
      onMouseEnter={isDesktop ? handleMouseEnter : undefined}
      onMouseLeave={isDesktop ? handleMouseLeave : undefined}
      onClick={handleClick}
      $clicked={isPlaying}
      $index={index}
      {...trackAudio}
    >
      <audio ref={audioRef} src={audioSrc} />
      <ScrollableContent>
        <DefaultContainer>
          {renderGroupCards()}
          {renderGroupCards()}
        </DefaultContainer>
        <TranscriptContainer>
          {renderTranscriptionContent()}
          {renderTranscriptionContent()}
        </TranscriptContainer>
      </ScrollableContent>
    </Row>
  );
};

export default React.memo(AudioRow);
