import { useContext } from 'react';
import { Paragraph } from '@swordhealth/ui-corporate';

import ClientContext from '@/contexts/client.context';

import { Arrow } from '@/components/core/Dynamic/SolutionConditions/styles';
import EnrollButton from '@/app/components/core/EnrollButton';

const CampaignButton = ({ index, label, productKey, scrollTop, conditionLabel, isOrganicLP }) => {
  const { openModal, client } = useContext(ClientContext);

  return (
    <EnrollButton
      id={`condition-${productKey}-${index}`}
      isActive={client.isActive}
      buttonLocation="solutions"
      openModal={() =>
        openModal({
          buttonText: label,
          buttonLocation: 'solutions',
          conditionLabel: conditionLabel,
        })
      }
      client={client}
      buttonLabel={
        <>
          <Paragraph size="lg">{label}</Paragraph>
          <Arrow />
        </>
      }
      product={client?.product}
      redirectToApp={client?.redirectToApp}
      trackingLabel={label}
      scrollTop={scrollTop}
      conditionLabel={conditionLabel}
      isOrganicLP={isOrganicLP}
      productKey={productKey}
    />
  );
};

export default CampaignButton;
