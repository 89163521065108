import { useContext } from 'react';
import { useSearchParams } from 'next/navigation';
import Image from 'next/image';

import { Button, Heading, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';
import { Container } from '@/components/ui/flexbox';
import { compareDates } from '@/utils/dates';
import { products } from '@/utils/products';
import { ACTIONS, trackButtonClicked } from '@/utils/track';
import { Content, ButtonWrapper, UnitHeader, ImageWrapper, Arrow } from './styles';
import CampaignButton from './CampaignButton';
import ClientContext from '@/contexts/client.context';

const SolutionConditions = ({ content, campaign, client, scrollTop, productKey, isOrganicLP }) => {
  const { id, upperlabel, sectionHeader, conditions, hideInfoProduct, config } = content;

  const {
    client: { launchDate, isActive },
  } = useContext(ClientContext);
  const searchParams = useSearchParams();
  const params = searchParams.toString();

  const { title, titleSize, titleSeoLevel, description, textLink, buttonGroup, xAlign } =
    sectionHeader;
  if (conditions?.data?.length < 1 || compareDates(launchDate) || !isActive) return null;

  const solutions = [products.BLOOM.key, products.MOVE.key];
  const isBloomOrMove = solutions.includes(productKey);

  const handleConditionClick = (condition, solution) => {
    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_COVERAGE,
      buttonText: condition?.label,
      buttonLocation: 'Solution Conditions',
      buttonSolution: solution?.attributes?.product?.data?.attributes?.name,
    });
  };

  return (
    <section id={`solution-conditions-section-${id}`}>
      <Container>
        <SectionHeader
          title={title}
          label={upperlabel}
          titleSize={titleSize}
          titleSeoLevel={titleSeoLevel}
          description={description}
          textLink={textLink}
          buttonGroup={buttonGroup}
          xAlign={xAlign}
        />
        <Content>
          {conditions?.data?.map((solution, index) => {
            const { title, product, conditions } = solution.attributes;
            if (!product || !product?.data) return null;
            const { logo, productKey } = product.data.attributes;
            if (conditions.length > 0)
              return (
                <>
                  {!hideInfoProduct && (
                    <UnitHeader>
                      <ImageWrapper>
                        <Image
                          fill
                          src={
                            logo
                              ? logo?.data?.attributes?.url
                              : products[productKey.toUpperCase()]?.logo
                          }
                        />
                      </ImageWrapper>
                      <Heading as="h3" size="xl" id={`solution-${productKey}-${index}`}>
                        {title}
                      </Heading>
                    </UnitHeader>
                  )}
                  <ButtonWrapper $colored={config?.colored}>
                    {conditions.map((condition, index) => {
                      const programType = products[productKey.toUpperCase()]?.param;
                      const conditionLabel = condition?.label;
                      const encodedCondition = btoa(conditionLabel).replace(/=+$/, '');
                      const destinationUrl = params
                        ? `/eligibility-check?solution=${productKey}&cond=${encodedCondition}&hide_get_started=true&program_type=${programType}&${params}`
                        : `/eligibility-check?solution=${productKey}&cond=${encodedCondition}&hide_get_started=true&program_type=${programType}`;
                      return (
                        <>
                          {(campaign || client || isBloomOrMove || scrollTop || isOrganicLP) && (
                            <CampaignButton
                              index={index}
                              label={condition.label}
                              productKey={productKey}
                              scrollTop={scrollTop}
                              conditionLabel={encodedCondition}
                              client={client}
                              isOrganicLP={isOrganicLP}
                            />
                          )}
                          {!campaign && !client && !isBloomOrMove && !scrollTop && !isOrganicLP && (
                            <Button
                              key={`conditions_${productKey}_${index}`}
                              id={`condition-${productKey}-${index}`}
                              variant={config?.colored ? 'white' : 'tertiary'}
                              href={destinationUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => handleConditionClick(condition, solution)}
                            >
                              <Paragraph size="lg">{condition.label}</Paragraph>
                              <Arrow />
                            </Button>
                          )}
                        </>
                      );
                    })}
                  </ButtonWrapper>
                </>
              );
          })}
        </Content>
      </Container>
    </section>
  );
};
SolutionConditions.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: true,
  spaceTop: false,
  marginBottom: false,
  withGradient: false,
};
export default SolutionConditions;
