import { useState } from 'react';
import Image from 'next/image';
import getImgSrcWorkaround from '@/utils/images';
import {
  CustomCard,
  ImageContainer,
  GradientIcon,
  WhiteIcon,
  CustomBlockquote,
  EllipsisParagraph,
  Quote,
  CustomPlayButton,
  PlayButton,
} from './styles';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';

const LARGE_QUOTE_SIZE = 363;

const TestimonialImage = ({ url, alternativeText, ...props }) => (
  <Image
    {...props}
    src={getImgSrcWorkaround(url)}
    alt={alternativeText || ''}
    {...(props.width && props.height ? {} : { fill: true, sizes: `${LARGE_QUOTE_SIZE}px` })}
  />
);

const Testimonial = ({
  quote,
  author,
  avatarImage,
  byline,
  image,
  video,
  solution,
  index,
  id,
  colored,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasVideo = Boolean(video?.data?.attributes?.url ?? video?.url);

  return (
    <>
      {hasVideo && (
        <VideoPlayerModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          videoURL={video?.url ?? video?.data?.attributes?.url}
          id={`testimonial-video-${index}-${id}`}
        />
      )}
      <CustomCard
        hasHoverEffect={!!video}
        $withImage={!!image && !hasVideo}
        $withVideo={hasVideo}
        $colored={colored}
        id={`testimonial-card-${index}-${id}`}
      >
        {!!image && (
          <ImageContainer>
            <TestimonialImage
              {...image?.data?.attributes}
              id={`testimonial-image-${index}-${id}`}
            />
          </ImageContainer>
        )}
        {hasVideo && (
          <CustomPlayButton
            onClick={() => setModalIsOpen(true)}
            size="sm"
            variant="primary"
            rounded
            id={`video-play-${index}`}
          >
            <PlayButton title={`Play Testimonial Video`} />
          </CustomPlayButton>
        )}
        <CustomBlockquote $withImage={!!image && !hasVideo} $withVideo={hasVideo}>
          {!!image || hasVideo ? <WhiteIcon /> : <GradientIcon />}
          <Quote weight="semibold" id={`testimonial-quote-${index}-${id}`}>
            {quote}
          </Quote>
          {(author || byline || solution) && (
            <footer>
              {avatarImage?.data && (
                <TestimonialImage {...avatarImage?.data?.attributes} width={48} height={48} />
              )}
              <div>
                {author && (
                  <EllipsisParagraph
                    size="sm"
                    weight="semibold"
                    id={`testimonial-author-${index}-${id}`}
                  >
                    {author}
                  </EllipsisParagraph>
                )}
                {solution && (
                  <EllipsisParagraph size="sm" style={{ textDecoration: 'underline' }}>
                    {solution}
                  </EllipsisParagraph>
                )}
                {byline && (
                  <EllipsisParagraph size="sm" id={`testimonial-byline-${index}-${id}`}>
                    {byline}
                  </EllipsisParagraph>
                )}
              </div>
            </footer>
          )}
        </CustomBlockquote>
      </CustomCard>
    </>
  );
};
export default Testimonial;
