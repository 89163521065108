import styled, { css } from 'styled-components';
import ArrowSVG from '/public/icons/arrow.svg';

export const Content = styled.div`
  padding-top: var(--ui-spacings-4xl);
  margin-bottom: -var(--ui-spacings-2xl);
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: var(--ui-spacings-xl);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: var(--ui-spacings-lg);
  }
`;

export const UnitHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--ui-spacings-sm);
  margin-bottom: var(--ui-spacings-lg);
  color: var(--ui-colors-grey-700);
  width: 460px;
  font-size: var(--ui-font-heading-2xl-size-lg);
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 545px;
    margin-bottom: 24px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
  }
`;
export const ImageWrapper = styled.div`
  height: 14px;
  position: relative;
  & > img {
    width: auto !important;
  }
`;
export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin-bottom: var(--ui-spacings-2xl);

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
  }

  & > a,
  & > button {
    min-height: 84px;
    color: var(--ui-colors-grey-900);
    transition: all 0.3s ease;

    ${(props) =>
      !props.$colored &&
      css`
        --button-bg: var(--ui-colors-neutral-200);
        --button-hover-bg: var(--ui-colors-neutral-200);
        --button-focus-bg: var(--ui-colors-neutral-200);
        --button-border: var(--ui-colors-neutral-200);
        --button-hover-border: var(--ui-colors-neutral-200);
        --button-focus-border: var(--ui-colors-neutral-200);
      `};
    ${(props) =>
      props.$colored &&
      css`
        --button-bg: var(--ui-colors-white);
        --button-hover-bg: var(--ui-colors-neutral-400);
        --button-focus-bg: var(--ui-colors-neutral-400);
        --button-border: var(--ui-colors-white);
        --button-hover-border: var(--ui-colors-neutral-200);
        --button-focus-border: var(--ui-colors-neutral-200);
      `};

    &:hover > span,
    &:focus > span {
      & > p {
        font-weight: var(--ui-font-weight-semibold);
        color: var(--ui-colors-grey-900);
      }
      & > svg > path {
        fill: var(--ui-colors-primary-500);
      }
    }

    & > svg > path,
    & > span > p {
      transition: all 0.3s ease;
    }

    & > span > p {
      color: var(--ui-colors-grey-900);
    }

    & > span {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s ease;
    }
  }
`;

export const Arrow = styled(ArrowSVG)`
  height: 16px;
  width: 9px;
  path {
    fill: ${(props) => props.theme.colors.grey[900]};
  }
`;
