import { useCustomCursor } from '../hook/useCustomCursor';
import { CursorContainer, CursorInner, CursorBackground } from './styles';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const CustomCursor = ({ containerRef, isHovering, isClicked }) => {
  const { springX, springY, springScale } = useCustomCursor(containerRef);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  if (!isDesktop) return null;

  return (
    <CursorContainer style={{ x: springX, y: springY, scale: springScale }}>
      <CursorInner>
        <CursorBackground $hovering={isHovering} $clicked={isClicked} />
      </CursorInner>
    </CursorContainer>
  );
};

export default CustomCursor;
