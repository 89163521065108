import AudioRow from '@/components/core/Dynamic/AudioList/AudioRow/AudioRow';
import {
  StyledSectionHeader,
  AudioListWrapper,
  Info,
  VolumeParagraph,
  ClickableParagraph,
} from './styles';
import { useEffect, useRef, useState, useCallback } from 'react';
import CustomCursor from '@/components/core/Dynamic/AudioList/CustomCursor/CustomCursor';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const AudioList = ({ content = {} }) => {
  const { sectionHeader = {}, infoLabel, clickableLabel, audioList = [] } = content;

  const [currentAudio, setCurrentAudio] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [hovering, setHovering] = useState(false);
  const containerRef = useRef(null);

  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  useEffect(() => {
    const handleClick = () => setClicked(true);

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleAudioPlay = useCallback(
    (audioRef) => {
      if (currentAudio && currentAudio !== audioRef.current) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(audioRef.current);
    },
    [currentAudio],
  );

  return (
    <div>
      <CustomCursor containerRef={containerRef} isClicked={clicked} isHovering={hovering} />
      {sectionHeader && (
        <StyledSectionHeader
          description={sectionHeader?.description}
          label={sectionHeader?.upperLabel}
          title={sectionHeader?.title}
          titleSize={sectionHeader?.titleSize}
          xAlign="center"
        />
      )}
      <Info>
        <VolumeParagraph size="xs">{infoLabel}</VolumeParagraph>
        {!isDesktop && <ClickableParagraph size="xl">{clickableLabel}</ClickableParagraph>}
      </Info>
      <AudioListWrapper
        ref={containerRef}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {audioList.map((audio, index) => (
          <AudioRow
            key={audio.id || index}
            index={index}
            audio={audio}
            currentAudio={currentAudio}
            onPlayAudio={handleAudioPlay}
          />
        ))}
      </AudioListWrapper>
    </div>
  );
};

AudioList.defaultConfig = {
  rounded: true,
  colored: true,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default AudioList;
