import { Paragraph } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  background-color: var(--ui-colors-neutral-default);
  color: var(--ui-colors-grey-800);
  border-bottom: 1px solid var(--ui-colors-grey-light);
  padding: 4px 0;
  text-align: center;
  ${(props) =>
    props.$gradient &&
    css`
      background: linear-gradient(91.26deg, #5993ea 0.63%, #ff8283 98.97%);
      color: var(--ui-colors-white);
      border-bottom: unset;
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 8px 0;
  }
`;

export const CustomParagraph = styled(Paragraph)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 14px;
  }
`;
