import { Paragraph, Counter as UICounter } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';
import CountUp from 'react-countup';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const CounterSection = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { id, counterValue, isSymbolLeft, symbol } = content;
  const counterValueNumber = Number(counterValue);
  const isNumeric = !isNaN(counterValueNumber);
  const notColored = (content?.config !== undefined || null) && content?.config?.colored === false;

  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  const renderCounter = useMemo(() => {
    if (!isNumeric) return counterValue; // Render as string if not a number

    const startValue = Math.max(0, counterValueNumber * 0.9);

    return (
      <CountUp
        start={startValue}
        end={counterValueNumber}
        duration={3}
        prefix={isSymbolLeft ? symbol : ''}
        suffix={!isSymbolLeft ? symbol : ''}
      />
    );
  }, [counterValue, counterValueNumber, isSymbolLeft, symbol, isNumeric]);

  return (
    <Wrapper ref={ref} id={`counter-section-${id}`} $notColored={notColored}>
      {content?.upperLabel && <UpperLabel>{content?.upperLabel}</UpperLabel>}
      {isVisible && <UICounter {...content} counter={renderCounter} />}
    </Wrapper>
  );
};

CounterSection.defaultConfig = {
  rounded: false,
  colored: true,
  spaceBottom: true,
  spaceTop: false,
  marginBottom: false,
  withGradient: false,
};

export default CounterSection;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  background-color: transparent !important;

  > section {
    background-color: transparent !important;
    padding: 0 !important;

    ${(props) =>
      props.$notColored &&
      css`
        ul > li > div {
          background-color: var(--ui-colors-neutral-300);
        }
      `};
  }
`;

const UpperLabel = styled(Paragraph)`
  width: fit-content;
  background-color: var(--ui-colors-neutral-300);
  border-radius: var(--ui-border-radius-full);
  color: var(--ui-colors-grey-700);
  font-size: 12px;
  line-height: 20px;
  font-family: var(--ui-font-family-heading);
  font-weight: var(--ui-font-weight-heading-bold);
  letter-spacing: 2px;
  padding: 6px 16px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 32px;
`;
