'use client';

import { useSearchParams } from 'next/navigation';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { scrollToTop } from '@/utils/scrollToSection';

import useReducedMotion from '@/utils/useReducedMotion';
import { createGuardaLink, createOnboardingURL } from '@/utils/onboarding-urls';
import { compareDates } from '@/utils/dates';
import { redirectToOnboarding } from '@/app/_components/core/EnrollButton/EnrollButton';

export default function useUrlEnrollAttributes(props) {
  const { id, client, product, buttonLabel, buttonLocation, openModal } = props;
  const searchParams = useSearchParams();
  const isReduced = useReducedMotion();

  if (!client?.isActive) {
    return {
      'data-testid': 'enroll-disabled',
      disabled: true,
    };
  }

  if (compareDates(client?.launchDate)) {
    const params = new URLSearchParams(searchParams?.toString());
    params.append('client', client.clientKey);
    return {
      href: `/waiting-list?${params.toString()}`,
      'data-testid': 'enroll-soon',
      target: '_blank',
      rel: 'noopener noreferrer',
      label: 'Coming Soon',
    };
  }

  if (props?.referralUser) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: props.referralUser?.region,
      programType: product?.param,
      query: { ...Object.fromEntries(searchParams), 'referral-token': props.referralUser?.token },
      triage: props?.triage,
    });

    return {
      id,
      href,
      onClick: () => redirectToOnboarding(buttonLabel, client, buttonLocation, href, props?.triage),
      'data-testid': 'enroll-active',
      target: '_blank',
      rel: 'noopener noreferrer',
      label: buttonLabel,
    };
  }

  if (props?.redirectToApp && client?.clientKey) {
    const link = createGuardaLink({
      clientKey: client.institutionKey ?? client.clientKey,
      serviceApp: product?.app,
      programType: product?.param,
    });

    return {
      id,
      href: link,
      target: '_blank',
      rel: 'noopener noreferrer',
      label: props?.buttonApp?.label ?? product?.enrollLabel ?? 'Enroll Now',
    };
  }

  if (props?.scrollTop || (props?.redirectToApp && !client.clientKey)) {
    return {
      id,
      onClick: (e) => {
        scrollToTop({ isReduced }), { 'data-testid': 'enroll-scroll' };
        e.preventDefault();
      },
      target: '_blank',
      rel: 'noopener noreferrer',
      label: buttonLabel,
    };
  }

  if (client?.clientKey && (!client?.countries || client?.countries?.length < 2) && buttonLabel) {
    const href = !client?.onboardingUrl
      ? createOnboardingURL({
          clientKey: client.institutionKey ?? client.clientKey,
          countryCode: client?.country?.data?.attributes?.ISOCode,
          programType: product?.param,
          query: Object.fromEntries(searchParams),
          triage: props?.triage,
        })
      : client?.onboardingUrl;

    return {
      id,
      onClick: () =>
        redirectToOnboarding(
          buttonLabel,
          client,
          buttonLocation,
          href,
          props?.triage,
          props?.trackingLabel,
        ),
      href,
      'data-testid': 'enroll-active',
      target: '_blank',
      rel: 'noopener noreferrer',
      label: buttonLabel,
    };
  }

  if (props?.isPortugueseLP) {
    const href = '/thrive/registo/formulario';
    return {
      id: `${id}-enroll-button-pt`,
      href,
      'data-testid': 'enroll-pt',
      onClick: () => {
        trackButtonClicked({
          action: ACTIONS.REDIRECT_TO_FORM,
          buttonType: BUTTON_TYPES.NAVIGATION,
          buttonText: { buttonLabel },
          destination: { href },
          buttonLocation: buttonLocation,
        });
      },
      target: '_blank',
      rel: 'noopener noreferrer',
      label: buttonLabel,
    };
  }

  if (props?.isOrganicLP) {
    const href = searchParams?.toString()
      ? `/eligibility-check/?${searchParams}${client ? `&client=${client}` : ''}`
      : `/eligibility-check${client ? `&client=${client}` : ''}`;
    return {
      id,
      href,
      'data-testid': 'enroll-organic-lp',
      onClick: () => {
        trackButtonClicked({
          action: ACTIONS.REDIRECT_TO_COVERAGE,
          buttonType: BUTTON_TYPES.NAVIGATION,
          buttonText: { buttonLabel },
          destination: { href },
          buttonLocation: buttonLocation,
        });
      },
      target: '_blank',
      rel: 'noopener noreferrer',
      label: buttonLabel,
    };
  }

  return {
    id,
    onClick: (e) => {
      openModal({ buttonText: buttonLabel, buttonLocation: 'panel-list' });
      e.preventDefault();
    },
    'data-testid': 'enroll-modal',
    target: '_blank',
    rel: 'noopener noreferrer',
    label: buttonLabel,
  };
}
