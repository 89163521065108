import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const CursorContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
`;

export const CursorInner = styled.div`
  transform: translate(-75%, -75%);
  position: relative;
  display: flex;
`;

const getCursorSize = ({ $hovering, $clicked }) => (!$hovering || $clicked ? '18px' : '64px');

export const CursorBackground = styled(motion.div)`
  width: ${getCursorSize};
  height: ${getCursorSize};
  border-radius: ${({ $hovering, $clicked }) => (!$hovering || $clicked ? '50%' : '100%')};
  ${({ $hovering, $clicked }) =>
    !$hovering || $clicked
      ? css`
          background-color: black;
          background-image: none;
        `
      : css`
          background-color: transparent;
          background-image: url('/assets/icons/circle_play_black.svg');
          background-size: cover;
          background-repeat: no-repeat;
        `}
`;
