import styled, { css } from 'styled-components';
import { LogoGrid } from '@swordhealth/ui-corporate';

export const StyledLogoGrid = styled(LogoGrid)`
  padding: 0 0 24px !important;
  background-color: transparent !important;

  img {
    opacity: 1 !important;
  }

  ${(props) =>
    props.theme === 'white' &&
    css`
      background-color: var(--ui-colors-white);
    `};
`;
