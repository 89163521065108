import { Heading, Paragraph } from '@swordhealth/ui-corporate';

import {
  BackgroundSection,
  CustomContainer,
  HeaderContainer,
  BackgroundGradient,
  ButtonContainer,
  ResetButton,
  ShadowButton,
  BackgroundGradientPressed,
  Wrapper,
  Content,
} from './styles';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { ACTIONS, trackButtonClicked } from '@/utils/track';
import TopBar from '@/app/_components/campaign/TopBar';

const Overlay = ({ content }) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(true);
  const [isPressed, setIsPressed] = useState(false);
  const { title, description, buttonLabel, disclaimer } = content;

  useEffect(() => {
    // const show_ovelay = localStorage.getItem(`show_overlay_${campaign}`);
    // setOverlayIsOpen(!show_ovelay ? true : false);
    const timeout = setTimeout(() => {
      if (!isPressed) {
        setOverlayIsOpen(false);
        document.documentElement.classList.remove('ui-html-no-scroll');
        trackButtonClicked({
          action: ACTIONS.REDIRECT_TO_LP,
          buttonLocation: 'overlay',
          buttonText: 'close_overlay',
        });
      }
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isPressed]);

  useEffect(() => {
    if (overlayIsOpen) {
      window.scrollTo(0, 0);
      document.documentElement.classList.add('ui-html-no-scroll');
    }
  }, [overlayIsOpen]);

  const handleButtonClick = () => {
    setIsPressed(!isPressed);

    const timer = setTimeout(() => {
      setOverlayIsOpen(false);
      // localStorage?.setItem(`show_overlay_${campaign}`, true);
      document.documentElement.classList.remove('ui-html-no-scroll');
      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_LP,
        buttonLocation: 'overlay',
        buttonText: buttonLabel,
      });
    }, 1300);

    return () => clearTimeout(timer);
  };

  return (
    <BackgroundSection
      id="overlaySection"
      $closed={!overlayIsOpen}
      className={!overlayIsOpen ? 'overlayIsClosed' : ''}
    >
      <TopBar darkMode showEnrollButton={false} />
      <Wrapper>
        <CustomContainer centered $closed={!overlayIsOpen}>
          <Content>
            <HeaderContainer as="header" xAlign="center">
              <Heading as="h1" size="4xl" variant="gradient" weight="bold">
                {title}
              </Heading>

              <Paragraph weight="regular" size="md">
                {description}
              </Paragraph>
            </HeaderContainer>
            <ButtonContainer className={isPressed ? 'pressed' : ''}>
              <ShadowButton className={isPressed ? 'pressed' : ''}>
                <ResetButton size="lg" variant="transparent" onClick={() => handleButtonClick()}>
                  <Image
                    priority
                    alt="rotate arrow"
                    className={isPressed ? 'pressed' : ''}
                    src="/assets/icons/ic_reset.png"
                    width={48}
                    height={48}
                  />
                  {buttonLabel}
                </ResetButton>
              </ShadowButton>
            </ButtonContainer>
            <Paragraph size="sm">{disclaimer}</Paragraph>{' '}
          </Content>
        </CustomContainer>
      </Wrapper>
      <BackgroundGradient $isPressed={isPressed} />
      <BackgroundGradientPressed $isPressed={isPressed} />
    </BackgroundSection>
  );
};

export default Overlay;
