import { StyledLogoGrid } from './styles';

const ClinicalGrade = ({ content }) => {
  const {
    titles: { upperLabel, title, description },
    companies,
    id,
  } = content;

  const filteredCompanies = companies.data.map((company) => company.attributes);

  return (
    <StyledLogoGrid
      id={`logo-grid-${id}`}
      sectionHeader={{
        description: description,
        label: upperLabel,
        title: title,
        titleSize: '2xl',
        xAlign: 'center',
      }}
      logoList={filteredCompanies}
    />
  );
};

ClinicalGrade.defaultConfig = {
  rounded: true,
  colored: true,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default ClinicalGrade;
