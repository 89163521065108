import { Container, Row, Col } from '@/components/ui/flexbox';

import { TitleWrapper, MainTitleCol } from './Title';
import List from './List';
import { Heading } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

const EffectiveTherapyPrograms = ({ content }) => {
  if (!content || !content?.effectiveStats?.length) return null;

  const { title, effectiveStats, id } = content;

  return (
    <Container>
      <Row>
        <MainTitleCol xs={12} lg={6}>
          <TitleWrapper id={`effective-therapy-title-${id}`}>
            <Heading as="h2" size="2xl" weight="bold">
              <MarkdownRichText elements={['strong', 'b', 'i', 'underline', 'u']}>
                {title}
              </MarkdownRichText>
            </Heading>
          </TitleWrapper>
        </MainTitleCol>

        <Col xs={12} lg={6}>
          <List id={id} list={effectiveStats} />
        </Col>
      </Row>
    </Container>
  );
};

EffectiveTherapyPrograms.defaultConfig = {
  rounded: false,
  colored: true,
  spaceBottom: true,
  spaceTop: true,
  marginBottom: true,
};

export default EffectiveTherapyPrograms;
