import { getImgData } from '@/utils/images';
import { QuotesCarousel as UIQuotesCarousel } from '@swordhealth/ui-corporate';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const textSize = (text) => {
  const length = text.length;

  if (length <= 125) return 'lg';
  if (length <= 250) return 'md';

  return 'sm';
};

export default function QuoteCarousel({ quotesList, ...props }) {
  return (
    <UIQuotesCarousel
      {...props}
      paddingBottom={false}
      as="div"
      theme={props?.config?.colored || QuoteCarousel.defaultConfig.colored ? 'neutral' : 'white'}
      quoteList={quotesList.map((quote) => {
        const finalImage = getImgData(quote?.image);
        return {
          ...quote,
          image: finalImage?.data === null ? null : finalImage,
          size: textSize(quote?.quote),
        };
      })}
    />
  );
}
QuoteCarousel.defaultConfig = {
  rounded: true,
  colored: true,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};
