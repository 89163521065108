import { Container } from '@/components/ui/flexbox';
import { GradientCircle, Button, SectionWrap } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 60px;
  overflow: hidden;
`;
export const BackgroundSection = styled.div`
  background-color: var(--ui-colors-grey-900);
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ui-colors-white);
  overflow: hidden;

  z-index: 2147483648;
  opacity: 1;
  transition: all 0.3s ease;

  @keyframes xoo {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      z-index: -100001;
    }
  }

  ${(props) =>
    props.$closed &&
    css`
      animation: xoo 0.6s linear;
      animation-fill-mode: forwards;
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 100%;
`;
export const CustomContainer = styled(Container)`
  overflow: auto;
  padding-bottom: 100px;
  width: 100%;
  max-width: unset;
  height: 100%;
  min-height: 100vh;

  opacity: 1;
  @keyframes fadeInOpacity {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0 36px 96px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0 24px 64px;
  }

  ${(props) =>
    props.$closed &&
    css`
      opacity: 0;
    `};
`;
export const HeaderContainer = styled(SectionWrap)`
  margin-top: 20px;
  max-width: 640px;
  --section-bg: transparent;
  color: var(--ui-colors-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: unset;
    gap: 16px;
  }
  h1 {
    white-space: break-spaces;

    font-size: var(--ui-font-heading-4xl-size-lg);
    font-weight: var(--ui-font-weight-body-regular);
    line-height: var(--ui-font-heading-line-height-5xl);

    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: var(--ui-font-heading-4xl-size-md);
      font-weight: var(--ui-font-weight-body-regular);
      line-height: var(--ui-font-heading-line-height-3xl);
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: var(--ui-font-heading-4xl-size-sm);
      font-weight: var(--ui-font-weight-body-regular);
      line-height: var(--ui-font-heading-line-height-xl);
    }
  }
`;
export const BackgroundGradient = styled(GradientCircle)`
  --gradient-opacity: 0.8;
  position: absolute;
  top: calc(55% + 60px);
  overflow: hidden;
  width: 600px;
  height: 350px;
  z-index: -20;
  opacity: 1;
  transform: translateY(20%);
  @keyframes springy {
    0% {
      transform: translateY(20%);
    }
    20% {
      transform: translateY(15%);
    }
    30% {
      transform: translateY(0);
    }
    31% {
      transform: scale(1, 1) translateY(0);
    }
    50% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    70% {
      transform: scale(0.9, 1.1) translateY(0);
    }
    90% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
  animation-name: springy;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 5s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;

  transition: opacity 0.3s;
  -webkit-backdrop-filter: blur(100px);
  -moz-backdrop-filter: blur(100px);
  -o-backdrop-filter: blur(100px);
  -ms-backdrop-filter: blur(100px);
  ${(props) =>
    props.$isPressed &&
    css`
      opacity: 0;
    `};
`;

export const BackgroundGradientPressed = styled(GradientCircle)`
  --gradient-opacity: 0.8;
  position: absolute;
  top: calc(55% + 60px);
  overflow: hidden;
  width: 600px;
  height: 350px;
  z-index: -20;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  -moz-filter: blur(100px);
  -o-filter: blur(100px);
  -ms-filter: blur(100px);
  background-image: linear-gradient(
    140.32deg,
    rgba(89, 147, 234, 0.8) 14.96%,
    rgba(255, 130, 131, 0.8) 70.39%
  );

  opacity: 0;
  transition: opacity 0.3s;

  ${(props) =>
    props.$isPressed &&
    css`
      opacity: 1;
    `};
`;

export const ButtonContainer = styled.div`
  position: relative;
  border-radius: 100px;
  padding: 10px;

  border: 1px solid #ecedf0;
  // background-image: linear-gradient(
  //   91.26deg,
  //   rgba(255, 130, 131, 0.4) 0.63%,
  //   rgba(89, 147, 234, 0.4) 98.97%
  // );
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  -moz-backdrop-filter: blur(50px);
  -o-backdrop-filter: blur(50px);
  -ms-backdrop-filter: blur(50px);
  position: relative;
  transform-style: preserve-3d;

  cursor: pointer;
  margin-top: 120px;
  margin-bottom: 24px;
  transition: all 0.3s ease;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 64px;
  }

  &::before {
    z-index: -12;
    content: '';
    position: absolute;
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    border-radius: inherit;
    pointer-events: none;
    inset: 0;
    transform: translate(-30px, -30px);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    box-shadow: 0px 4px 10px 0px #00000040;
    padding: 12.5px;
    margin-top: 115px;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      margin-top: 59px;
    }

    &::before {
      opacity: 1;
    }
    & > div {
      &::before {
        opacity: 1;
      }
      & > button {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &.pressed {
    background-image: linear-gradient(
      140.32deg,
      rgba(89, 147, 234, 0.4) 0.63%,
      rgba(255, 130, 131, 0.4) 98.97%
    );
    box-shadow: 0px 4px 10px 0px #00000040;
    padding: 12.5px;
    margin-top: 115px;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      margin-top: 59px;
    }

    &::before {
      opacity: 1;
    }

    & > div {
      &::before {
        opacity: 1;
      }
      & > button {
        box-shadow: 0px 4px 10px 0px #00000080;
        transition: all 0.3s;
        &::before {
          opacity: 0;
        }
      }
    }
  }
`;

export const ShadowButton = styled.div`
  position: relative;
  background-image: linear-gradient(91.26deg, #000000 25.22%, #3e424c 98.97%);
  border-radius: 88.24px;
  padding: 2px;
  z-index: -4;
  opacity: 1;

  &::before {
    transition: opacity 0.3s;
    background-image: linear-gradient(98.49deg, #1f222c 25%, #ff8283 50%, #5993ea 100%);
    content: '';
    border-radius: 88.24px;
    padding: 2px;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: -4;
  }
  &.pressed {
    &::before {
      background-image: linear-gradient(98.49deg, #1f222c 25%, #5993ea 50%, #ff8283 100%);
    }
  }
`;

export const ResetButton = styled(Button)`
  --button-hover-color: var(--ui-colors-white);
  --button-focus-color: var(--ui-colors-white);
  --button-focus-border: transparent;
  --button-hover-border: transparent;
  --button-border-width: 0px;
  position: relative;

  padding: 36px 56px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-radius: 88.24px !important;

  background-image: linear-gradient(100.17deg, #000000 38.5%, #3e424c 100%);
  box-shadow: 0px 4px 10px 0px #00000040;

  font-size: 48px;
  line-height: 58px;
  font-family: var(--ui-font-family-heading);
  font-weight: var(--ui-font-weight-normal);
  color: var(--ui-colors-white);
  z-index: -2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 24px 40px;
    font-size: 34px;
    line-height: 40px;
    img {
      height: 34px !important;
      width: 34px !important;
    }
  }

  &:focus {
    box-shadow: 0px 4px 10px 0px #00000080;
    transition: all 0.3s;
    &::before {
      opacity: 0;
    }
  }

  &::before {
    background-image: linear-gradient(100.17deg, #1f222c 38.5%, #3e424c 100%);
    box-shadow: 0px 4px 10px 0px #00000080;
    transition: opacity 0.3s;
    border-radius: 88.24px;
    content: '';
    inset: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  & > span > img.pressed {
    animation: spin 1.2s linear;
    animation-iteration-count: 1;
  }
`;
