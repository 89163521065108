import { PanelList as UIPanelList } from '@swordhealth/ui-corporate';
import PanelMedia from './PanelMedia';
import styled from 'styled-components';
import { useContext } from 'react';
import ClientContext from '@/contexts/client.context';
import useUrlEnrollAttributes from '@/utils/useUrlEnrollAttributes';
import { products } from '@/utils/products';

function PanelItem({ panel, client, media, scrollBehavior, solution, openModal }) {
  const isBloomOrMove =
    client?.product?.key === products.BLOOM.key ||
    (client?.product?.key === products.MOVE.key && client?.countries?.length > 0);
  const isThrive =
    client?.product?.key === products.THRIVE.key &&
    (!client.clientKey || client.countries.length > 0);

  const urlParams = useUrlEnrollAttributes({
    id: panel?.textLink?.id,
    client,
    product: client?.product,
    buttonLabel: panel?.textLink?.label,
    buttonLocation: 'panel-list',
    openModal,
    scrollTop: !isBloomOrMove && isThrive,
  });

  if (panel?.TextlinkToOnboarding) {
    return {
      ...panel,
      textLink: {
        ...panel?.textLink,
        ...urlParams,
        className: 'customTextLink',
      },
      solution: solution?.slug,
      mediaRatio: media?.mediaRatio || undefined,
      media: <PanelMedia media={media} offset={scrollBehavior === 'stickyImage'} />,
    };
  } else
    return {
      ...panel,
      solution: solution?.slug,
      mediaRatio: media?.mediaRatio || undefined,
      media: <PanelMedia media={media} offset={scrollBehavior === 'stickyImage'} />,
    };
}
export default function PanelList({ panelList, scrollBehavior, solution, ...props }) {
  const { client, openModal } = useContext(ClientContext);

  return (
    <>
      <UIPanelList
        {...props}
        as={CustomPanel}
        paddingBottom={false}
        scrollBehavior={scrollBehavior}
        panelList={panelList.map(({ media, ...panel }) =>
          PanelItem({ client, panel, media, scrollBehavior, solution, openModal }),
        )}
      />
    </>
  );
}
const CustomPanel = styled.div`
  padding-top: 0px !important;
  background-color: transparent !important;
  ul.checkmark {
    padding-left: 0;
    li {
      position: relative;
      padding-left: 42px;
      &::marker {
        content: '';
      }
      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 26px;
        height: 26px;
        left: 0;
        background: green;
        color: red;
        background: url('/assets/shapes/check.svg') no-repeat center center;
        content: '';
        background-size: 26px;
      }
    }
  }
  .customTextLink {
    font-size: 16px;
    line-height: 28px;
  }
`;
PanelList.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: true,
  spaceTop: false,
  marginBottom: false,
};
