import Image from 'next/image';

import { bustMediaCache, Video } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';
import getImgSrcWorkaround from '@/utils/images';

function Media({
  alternativeText = '',
  mime,
  objectFit,
  url,
  updatedAt,
  priority = false,
  ...props
}) {
  const finalMime = mime ?? props.data?.attributes?.mime;
  const image = url ?? props.data?.attributes?.url;
  const src = getImgSrcWorkaround(bustMediaCache(image, updatedAt));

  if (!src || !finalMime) {
    return null;
  }

  if (finalMime?.includes('video')) {
    return (
      <Video
        {...props}
        url={src}
        mime={mime}
        objectFit={objectFit || 'cover'}
        data-testid="media-video"
      />
    );
  }

  if (finalMime.includes('svg')) {
    return <img {...props} src={src} alt={alternativeText || ''} data-testid="media-svg" />;
  }

  if (finalMime.includes('image')) {
    return (
      <Image
        style={objectFit && { objectFit: 'contain' }}
        src={src}
        alt={alternativeText || ''}
        fill
        data-testid="media-image"
        priority={priority}
      />
    );
  }

  return null;
}

export default function PanelMedia({ media, offset = false }) {
  return (
    <MediaContent $mediaOffset={offset}>
      <Media objectFit={media.mediaFit} {...media?.media} />
    </MediaContent>
  );
}

const MediaContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  ${(props) =>
    !props.$mediaOffset &&
    css`
      @media (min-width: ${(props) =>
          props.theme.breakpoints.min_lg}px) and (prefers-reduced-motion: no-preference) {
        transform: translateY(calc(var(--header-height) / 2));
      }
    `};

  & > svg {
    max-width: 100%;
  }
`;
