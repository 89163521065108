import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  gap: 24px;
  padding-right: 24px;
  align-items: center;

  p {
    font-size: 4rem;
    line-height: 4.375rem;
    font-weight: 400;
    white-space: nowrap;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 2rem;
    }
  }

  img {
    border-radius: 16px;
    object-fit: cover;
  }
`;

export const ScrollableContent = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const GroupContainer = styled.div`
  display: flex;
  padding: 24px 0;
  animation: ${({ $left, $duration }) =>
    `${$left ? 'scroll-left' : 'scroll-right'} ${$duration}s linear infinite`};

  @keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes scroll-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const TranscriptionContainer = styled.div`
  display: flex;
  padding: 24px 0;
  animation: none;

  &:hover {
    animation: ${({ $duration }) =>
      $duration ? `scroll-left ${$duration}s linear 250ms infinite` : undefined};
  }

  @keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes scroll-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const TranscriptionText = styled.div`
  display: flex;
  gap: 24px;
  padding-right: 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[200]};
  width: 100%;

  p {
    font-size: 4rem;
    line-height: 4.375rem;
    font-weight: 400;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.neutral[200]};

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 2rem;
    }
  }
`;

export const DefaultContainer = styled.div`
  position: absolute;
  display: flex;
  opacity: 1;
  pointer-events: none;
`;

export const TranscriptContainer = styled.div`
  display: flex;
  opacity: 0;
`;

export const Row = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  border-top: ${({ $index, theme }) => $index === 0 && `1px solid ${theme.colors.grey[900]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints.min_lg}px) {
    &:hover {
      background: linear-gradient(91.26deg, #5993ea 0.63%, #ff8283 98.97%);
    }

    &:hover ${DefaultContainer} {
      opacity: 0;
    }

    &:hover ${TranscriptContainer} {
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    background: ${({ $clicked }) =>
      $clicked && 'linear-gradient(91.26deg, #5993ea 0.63%, #ff8283 98.97%)'};;
   ${DefaultContainer} {
     opacity: ${({ $clicked }) => ($clicked ? 0 : 1)};
    }

   ${TranscriptContainer} {
     opacity: ${({ $clicked }) => ($clicked ? 1 : 0)};
    }
  }
  }
`;
