import { useCallback, useRef, useState } from 'react';

export function useAudioPlayback(onPlayAudio) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const playAudio = useCallback(async () => {
    if (audioRef.current) {
      onPlayAudio(audioRef);
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error('Playback failed:', error);
      }
    }
  }, [onPlayAudio]);

  const stopAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, []);

  return { audioRef, isPlaying, playAudio, stopAudio };
}
