import { useState, useEffect } from 'react';

export default function useReadingTime(content, charsPerSecond = 20) {
  const [readingTime, setReadingTime] = useState(null);
  useEffect(() => {
    if (!content?.length) {
      return;
    }

    setReadingTime(content.length / charsPerSecond);
  }, [content, charsPerSecond]);

  return readingTime;
}
