import styled from 'styled-components';
import { Paragraph, SectionHeader } from '@swordhealth/ui-corporate';

export const StyledSectionHeader = styled(SectionHeader)`
  width: 100%;
`;

export const Info = styled.div`
  text-align: center;
  padding: 16px 0;
`;

export const VolumeParagraph = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey[500]};
`;

export const ClickableParagraph = styled(Paragraph)`
  font-size: 1.125rem;
  line-height: 2rem;
`;

export const AudioListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
  cursor: default;
`;
