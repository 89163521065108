import Image from 'next/image';
import { useContext, useEffect, useState, useMemo } from 'react';

import {
  ButtonWrapper,
  ImageWrapper,
  StyledParagraph,
  StyledSectionHeader,
  GradientBG,
  StyledContainer,
  LogosWrapper,
  SimpleHeroGradiant,
} from './styles';
import getImgSrcWorkaround from '@/utils/images';
import EnrollButton from '@/app/components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import { Logos } from '@/app/components/shared/Logos/Logos';
import { MarkdownRichText } from '@/app/components/shared';

const HeroThematic = ({ content }) => {
  const { openModal, client } = useContext(ClientContext);
  const [isVisible, setIsVisible] = useState(false);
  const {
    sectionHeader: { title, upperLabel, description },
    buttonLabel,
    disclaimer,
    animation = true,
    image,
    showLogos,
  } = content;

  useEffect(() => {
    const overlay = document.getElementById('overlaySection');
    if (!overlay) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    function verifyOverlay() {
      const overlay = document.getElementById('overlaySection');
      if (!overlay || overlay.classList.contains('overlayIsClosed')) {
        setIsVisible(true);
      }
    }
    window.addEventListener('animationend', verifyOverlay);

    return () => {
      window.removeEventListener('animationend', verifyOverlay);
    };
  }, [isVisible]);

  const logos = useMemo(() => {
    if (!client.logo?.data) return [];
    return Array.isArray(client.logo.data) ? client.logo.data.slice(0, 3) : [client.logo.data];
  }, [client?.logo]);

  return (
    <StyledContainer xAlign="center">
      {showLogos && (
        <LogosWrapper>
          <div>
            {client.clientKey ? (
              <Logos
                logos={logos}
                centered
                showSwordLogo={client.showSwordLogo}
                client={client}
                withBackground
              />
            ) : (
              <Logos logos={[]} centered showSwordLogo client={client} withBackground />
            )}
          </div>
        </LogosWrapper>
      )}

      <StyledSectionHeader
        title={<MarkdownRichText elements={['strong', 'br']}>{title}</MarkdownRichText>}
        label={upperLabel}
        description={description ?? ''}
        xAlign="center"
        titleSeoLevel="h1"
        animate={isVisible && animation}
        notAnimate={!animation}
      />

      <ButtonWrapper animate={isVisible && animation} notAnimate={!animation}>
        <EnrollButton
          id="header_button"
          isActive={client.isActive}
          buttonLocation="header"
          openModal={() => openModal({ buttonText: buttonLabel, buttonLocation: 'header' })}
          client={client}
          buttonLabel={buttonLabel}
          product={client?.product}
          redirectToApp={client?.redirectToApp}
        />
        {disclaimer && <StyledParagraph>{disclaimer}</StyledParagraph>}
      </ButtonWrapper>

      {image?.data ? (
        <ImageWrapper notAnimate={!animation} animate={isVisible && animation}>
          <GradientBG />
          <Image
            id="thematic_hero_image"
            src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
            alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
            objectFit="contain"
            width={624}
            height={392}
          />
        </ImageWrapper>
      ) : (
        <SimpleHeroGradiant />
      )}
    </StyledContainer>
  );
};

HeroThematic.defaultConfig = {
  rounded: false,
  colored: true,
  spaceBottom: true,
  spaceTop: true,
  marginBottom: false,
};

export default HeroThematic;
