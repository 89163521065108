'use client';

import { useCallback, useRef } from 'react';
import { usePathname } from 'next/navigation';
import { EVENT_NAMES, trackAudioPlayed } from './track';

export default function useAudioTracking({ src }) {
  const pathname = usePathname();
  const sessionId = useRef(Math.random().toString(36));
  const audioFirstPlayBack = useRef(true);
  const audioLength = useRef(0);
  const audioProgress = useRef(0);

  const trackAudio = useCallback(
    ({ event, ...eventData }) => {
      if (!src) {
        return;
      }

      trackAudioPlayed(event, {
        src,
        sessionId: sessionId.current,
        url: pathname,
        position: audioProgress.current,
        length: audioLength.current,
        percentageListened: `${(audioProgress.current / audioLength.current) * 100}%`,
        ...eventData,
      });
    },
    [pathname, src],
  );

  return {
    onCanPlay: (e) => {
      const audio = e.target;

      audioLength.current = Math.ceil(audio?.duration || 0);
    },
    onPlay: () => {
      const event = audioFirstPlayBack.current
        ? EVENT_NAMES.AUDIO_PLAY_START
        : EVENT_NAMES.AUDIO_PLAY_RESUME;

      audioFirstPlayBack.current = false;

      trackAudio({
        event,
      });
    },
    onEnded: () => {
      audioFirstPlayBack.current = true;
      audioProgress.current = audioLength.current;

      trackAudio({
        event: EVENT_NAMES.AUDIO_COMPLETED,
      });
    },
    onPause: () => {
      if (audioProgress.current >= audioLength.current) {
        return;
      }
      trackAudio({
        event: EVENT_NAMES.AUDIO_PAUSE,
      });
    },
    onTimeUpdate: useCallback((e) => {
      const audio = e.target;

      audioProgress.current = Math.ceil(audio?.currentTime);
    }, []),
    onAudioExit: () => {
      if (audioProgress.current >= audioLength.current) {
        return;
      }

      trackAudio({
        event: EVENT_NAMES.AUDIO_EXITED,
      });
    },
  };
}
