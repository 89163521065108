import styled, { css } from 'styled-components';

export const SPACE_DESKTOP = '136px';
export const SPACE_TABLET = '104px';
export const SPACE_MOBILE = '64px';

// colored -> background-color as neutral
// rounded  -> background-color as neutral + inner background as white + clip path
// rounded + colored  -> background-color as white + inner background as neutral + clip path

const Inner = styled.div`
  background-color: transparent;

  ${(props) =>
    !!props.rounded &&
    css`
      //CLIP-PATH
      clip-path: circle(farthest-side at 50% -10000px);
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        clip-path: circle(farthest-side at 50% -4000px);
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        clip-path: circle(farthest-side at 50% -2500px);
      }
    `}
`;

const Section = styled.section`
  overflow-x: clip;
  position: relative;

  margin-bottom: ${SPACE_DESKTOP};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: ${SPACE_TABLET};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: ${SPACE_MOBILE};
  }

  ${(props) =>
    props.withGradient &&
    !props.rounded &&
    `
    background: linear-gradient(180deg, #f7f4f2 0%, #ffffff 100%);
  `}

  ${(props) =>
    props.colored &&
    !props.rounded &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
      margin-bottom: ${SPACE_DESKTOP};

      ${props.withGradient &&
      css`
        background: linear-gradient(180deg, #ffffff 0%, #f7f4f2 100%);
      `}

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        margin-bottom: ${SPACE_TABLET};
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-bottom: ${SPACE_MOBILE};
      }
    `}

  ${(props) =>
    !!props.spaceTop &&
    css`
      ${Inner} {
        padding-top: ${SPACE_DESKTOP};
        @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
          padding-top: ${SPACE_TABLET};
        }
        @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
          padding-top: ${SPACE_MOBILE};
        }
      }
    `}
  
  ${(props) =>
    !!props.spaceBottom &&
    css`
      ${Inner} {
        padding-bottom: ${SPACE_DESKTOP};
        @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
          padding-bottom: ${SPACE_TABLET};
        }
        @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
          padding-bottom: ${SPACE_MOBILE};
        }
      }
    `}

  ${(props) =>
    props.rounded &&
    !props.colored &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
      ${Inner} {
        background-color: ${(props) => props.theme.colors.white};
        ${props.withGradient &&
        css`
          background: linear-gradient(180deg, #f7f4f2 0%, #ffffff 100%);
        `}
        padding-bottom: ${SPACE_DESKTOP};
        @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
          padding-bottom: ${SPACE_TABLET};
        }
        @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
          padding-bottom: ${SPACE_MOBILE};
        }
      }
    `}

  ${(props) =>
    props.rounded &&
    props.colored &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      ${Inner} {
        background-color: ${(props) => props.theme.colors.neutral.default};
        ${props.withGradient &&
        css`
          background: linear-gradient(180deg, #ffffff 0%, #f7f4f2 100%);
        `}
        padding-bottom: ${SPACE_DESKTOP};
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding-bottom: ${SPACE_TABLET};
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-bottom: ${SPACE_MOBILE};
      }
    `}

  ${(props) =>
    !props.marginBottom &&
    css`
      margin-bottom: 0;
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        margin-bottom: 0;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-bottom: 0;
      }
    `}
`;

export const PrettySection = ({
  children,
  rounded,
  colored,
  spaceBottom,
  spaceTop,
  marginBottom,
  id,
  withGradient,
}) => (
  <Section
    rounded={rounded}
    colored={colored}
    spaceBottom={spaceBottom}
    spaceTop={spaceTop}
    marginBottom={marginBottom}
    withGradient={withGradient}
    id={id}
  >
    <Inner rounded={rounded}>{children}</Inner>
  </Section>
);

export default Section;
