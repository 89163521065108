import { Container } from '@/components/ui/flexbox';
import { Wrapper, CustomParagraph } from './styles';

const TopBanner = ({ content }) => {
  const { title, backgroundGradient } = content;

  return (
    <Wrapper $gradient={backgroundGradient}>
      <Container>
        <CustomParagraph size="md">{title}</CustomParagraph>
      </Container>
    </Wrapper>
  );
};

export default TopBanner;
